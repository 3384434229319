import React from "react"
import { useState } from "react"
import { filterButton, pasc_blog } from "../styles/blogPage.module.css"
import AppButton from "./AppButton"
import BlogFilter from "./BlogFilter"

import {
  blog_container,
  wave,
  filterCaption,
  blobVector,
  blobVector2,
  blogHeader,
  waveBottom,
  blogList,
} from "../styles/blogPage.module.css"

import BlogCard from "./BlogCard"
export default function BlogPage({ blogs }) {
  const [filterOpen, setfilterOpen] = useState(false)

  return (
    <div className={`${blog_container}`}>
      <div className={`${blogHeader}`}>
        <img src="/blue-wave-Vector.svg" className={`${wave}`} alt="vec" />
        <div className={`visible ${pasc_blog} heading`}>PASC Blog</div>
        <AppButton
          title="Filter"
          onClick={() => setfilterOpen(!filterOpen)}
          size="large"
          icon="filter_list"
          variant="primary"
          className={`${filterButton}`}
        />
        {filterOpen && <BlogFilter />}

        <div className={`${filterCaption}`}>Showing all blogs</div>
      </div>
      <div className={`${blogList}`}>
        {blogs.map(blog => (
          <BlogCard blog={blog} key={blog._id} />
        ))}
      </div>

      <img src="/Blob_Vector1.png" className={`${blobVector}`} alt="vec" />
      <img src="/Blob_Vector2.png" className={`${blobVector2}`} alt="vec" />
      <img
        src="/bottom-blue-wave-vector.svg"
        className={`${waveBottom}`}
        alt="vec"
      />
    </div>
  )
}
