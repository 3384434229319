import React, { useContext, useEffect, useState } from "react"
import AppButton from "../components/AppButton"
import Banner from "../components/Banner"
import BlogPage from "../components/BlogPage"
import Layout from "../components/Layout"
import { navigate } from "gatsby"
import authContext from "../routes/context"
import modalContext from "../routes/ModalContext"
import API from "../apis"

const Blog = () => {
  const { authState } = useContext(authContext)
  const { setOpen } = useContext(modalContext)

  const [blogs, setBlogs] = useState([])

  const handleClick = () => {
    if (!authState.isAuthenticated) {
      setOpen("login")
    } else navigate("/app/addblog")
  }

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const data = await API.getApprovedBlogs()
        if (data?.error) {
          return
        }
        setBlogs(data.blogs)
      } catch (e) {
        console.log(e)
      }
    }
    fetchBlogs()
  }, [setBlogs])

  return (
    <Layout>
      <Banner
        title="Blogs"
        text="Armed with the knowledge of various domains, it is a platform that serves ideas mainly in the tech domain. Our Blog comprises ideas put forth by PICT Alumni on how to grab enriching internships at big tech firms and how to publish research papers at international conferences. You get to know more about the Machine learning sector, contribution to open source organizations, insights into the corporate world, glimpses of the Pulzion Event, and many more opportunities to evolve and learn in the technical domain."
        ButtonComponent={
          <AppButton
            title="Write Blog"
            icon="create"
            variant="secondary"
            onClick={handleClick}
          />
        }
        image="blog.png"
      />
      <BlogPage blogs={blogs} />
    </Layout>
  )
}

export default Blog
