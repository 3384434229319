// extracted by mini-css-extract-plugin
export var blog_container = "blogPage-module--blog_container--QVtnM";
export var blogHeader = "blogPage-module--blogHeader--BZAa6";
export var pasc_blog = "blogPage-module--pasc_blog--rPdto";
export var filterButton = "blogPage-module--filterButton--b8dYK";
export var filterCaption = "blogPage-module--filterCaption--SFQ4v";
export var bf_main = "blogPage-module--bf_main--yd053";
export var bf_pointer = "blogPage-module--bf_pointer--fHuVl";
export var bf_checkbox = "blogPage-module--bf_checkbox--AtxWb";
export var bf_topic = "blogPage-module--bf_topic--LVjo3";
export var wave = "blogPage-module--wave--iVB08";
export var waveBottom = "blogPage-module--waveBottom--ED5-h";
export var blobVector = "blogPage-module--blobVector--CQkQD";
export var blobVector2 = "blogPage-module--blobVector2--DfpS-";
export var blogcard = "blogPage-module--blogcard--V3W03";
export var blogCardImg = "blogPage-module--blogCardImg--BtoCl";
export var bloggerName = "blogPage-module--bloggerName--abiJE";
export var blogAvtar = "blogPage-module--blogAvtar--lMrvp";
export var blogTitle = "blogPage-module--blogTitle--qnqtj";
export var blogChips = "blogPage-module--blogChips--27jQ5";
export var blogDomainChip = "blogPage-module--blogDomainChip---TK4t";
export var blogDomainText = "blogPage-module--blogDomainText--625DQ";
export var blogExcerpt = "blogPage-module--blogExcerpt--4RQmy";
export var blogDate = "blogPage-module--blogDate--vmous";
export var blogList = "blogPage-module--blogList--4znRb";
export var sblog_container = "blogPage-module--sblog_container--RMOnm";
export var sblog_backButton = "blogPage-module--sblog_backButton--vKPwq";
export var sblog_title = "blogPage-module--sblog_title--o07hc";
export var sblog_domain = "blogPage-module--sblog_domain--n1kDL";
export var sblog_img = "blogPage-module--sblog_img--Rf1XN";
export var sblog_top_wave = "blogPage-module--sblog_top_wave--VMKSS";
export var sblog_bottom_wave = "blogPage-module--sblog_bottom_wave--JutbQ";
export var sblog_writtenby = "blogPage-module--sblog_writtenby--GvrTD";
export var sblog_avtar = "blogPage-module--sblog_avtar--Z+ekn";
export var sblog_name = "blogPage-module--sblog_name--5XNAs";
export var calender_logo = "blogPage-module--calender_logo--rdpID";
export var sblog_date = "blogPage-module--sblog_date--Brhr4";
export var sblog_main_content = "blogPage-module--sblog_main_content--hIOpB";
export var sblog_content = "blogPage-module--sblog_content--SafCy";
export var sblog_inner_image = "blogPage-module--sblog_inner_image--LogHr";